import React, {useCallback} from "react";

import {LocalStorageKeys, localStorageService} from "common/services/LocalStorageService";
import config from "config/index";
import defaultTranslations from "config/locales/en-US/translations.json";
import {useLocalStorage} from "@fleet/common/hooks/useLocalStorage";
import {BugsnagService} from "@fleet/common/services/bugsnag";
import {DefaultLocale, TranslationService} from "@fleet/common/services/translations";

type Translations = typeof defaultTranslations;
const fallbackTranslations: {[key: string]: Translations} = {
    [DefaultLocale]: defaultTranslations,
};

export interface Bugsnag {
    notify: (e: Error) => void;
    leaveBreadcrumb: (name: string, metadata?: Record<string, unknown>) => void;
    setUser: (id: string) => void;
}

export interface ThirdPartyState {
    changeLocale: (locale: string) => Promise<void>;
    bugsnag: Bugsnag;
}

const ThirdPartyContextProvider = React.createContext<ThirdPartyState>({
    changeLocale: () => Promise.resolve(),
    bugsnag: {
        notify: () => {},
        leaveBreadcrumb: () => {},
        setUser: () => {},
    },
});
ThirdPartyContextProvider.displayName = "ThirdPartyContextProvider";

const ThirdPartyProvider = ({children}: {children: React.ReactNode}) => {
    const [currentlySelectedLocale, setLocale] = useLocalStorage(
        localStorageService.getItemKey(LocalStorageKeys.LOCALE),
        DefaultLocale,
    );

    const {bugsnagNotify, ErrorBoundary, bugsnagLeaveBreadcrumb, bugsnagSetUser} = BugsnagService(
        config.isEnvLocal, // To test Bugsnag locally, disable this
        config.bugsnagApiKey,
        config.versionNumber,
        config.appStage,
        config.appBrand,
    );

    const onTranslationsFetchError = useCallback((e: Error) => bugsnagNotify(e), [bugsnagNotify]);

    const {RemoteIntlProvider, changeAppLocale} = TranslationService(
        config.baseUrl,
        config.crowdinFolder,
        config.defaultLocale,
        currentlySelectedLocale,
        config.crowdinBackendProject,
        config.crowdinFileName,
        fallbackTranslations,
    );

    const changeLocale = useCallback(
        async (locale: string) => {
            setLocale(locale);
            await changeAppLocale(locale);
        },
        [changeAppLocale, setLocale],
    );

    return (
        <ErrorBoundary>
            <RemoteIntlProvider onTranslationsFetchError={onTranslationsFetchError} key={config.defaultLocale}>
                <ThirdPartyContextProvider.Provider
                    value={{
                        changeLocale,
                        bugsnag: {
                            notify: bugsnagNotify,
                            leaveBreadcrumb: bugsnagLeaveBreadcrumb,
                            setUser: bugsnagSetUser,
                        },
                    }}
                >
                    {children}
                </ThirdPartyContextProvider.Provider>
            </RemoteIntlProvider>
        </ErrorBoundary>
    );
};

export {ThirdPartyContextProvider, ThirdPartyProvider};
