import {useCallback, useContext, useEffect, useState} from "react";
import {useLocation} from "react-router-dom";

import {ApplicationContextProvider} from "common/providers/appStateProvider";
import {ThirdPartyContextProvider} from "common/providers/appThirdPartyProvider";
import {ApiContextProvider} from "common/services/api/apiProvider";
import {getNewRefreshToken, logout} from "common/services/authService";
import {localStorageService} from "common/services/LocalStorageService";
import config from "config/index";
import {NotificationContext, NotificationType} from "@fleet/common/services/notificationProvider";

import {useI18n} from "./useI18n";

export const useAuthentication = () => {
    const {i18n} = useI18n();
    const {bugsnag} = useContext(ThirdPartyContextProvider);
    const appState = useContext(ApplicationContextProvider);
    const api = useContext(ApiContextProvider);
    const {setNotification} = useContext(NotificationContext);
    const location = useLocation();
    const [hasErrorOccurred, setHasErrorOccurred] = useState<boolean>(false);

    useEffect(() => {
        if (hasErrorOccurred) {
            setHasErrorOccurred(false);
            setNotification({
                type: NotificationType.ERROR,
                text: i18n("something_went_wrong"),
                timeout: 4000,
            });
        }
    }, [hasErrorOccurred, i18n, setNotification]);

    const setAppAuthenticated = useCallback(
        (refreshToken: string) => {
            appState.setRefreshToken(refreshToken);
            appState.setIsAuthenticated(true);
        },
        [appState],
    );

    const makeLogout = useCallback(async () => {
        if (api && appState.refreshToken) {
            try {
                await api.driverAuth.logout({refresh_token: appState.refreshToken});
            } catch (e) {
                bugsnag.notify(e as Error);
            }
        }

        await logout();

        appState.setRefreshToken(null);
        appState.setIsAuthenticated(false);
    }, [api, appState, bugsnag]);

    const getDriverPortalLoginUrl = (redirectPath: string) => {
        const language = localStorageService.getLanguageWithDefaultLocale();
        const baseUrl = config.driverPortalUrl; // Use for local development http://localhost:4200 and launch local DP
        const sanitizedRedirectPath = redirectPath.startsWith("/") ? redirectPath.slice(1) : redirectPath;
        return `${baseUrl}/login?lang=${language}&redirect_to=vehicle_marketplace&redirect_path=${sanitizedRedirectPath}`;
    };

    /**
     * @param redirectTo - Dont add prefix slash (/)
     */
    const redirectToDriverPortalForLogin = useCallback(
        <T extends string>(redirectTo?: T extends `/${string}` ? "Don't add prefix slash (/)" : T) => {
            const redirectPath = redirectTo || location.pathname + location.search;
            window.location.href = getDriverPortalLoginUrl(redirectPath);
        },
        [location.pathname, location.search],
    );

    const loginWithLocationHash = useCallback(
        async (locationHash: string) => {
            const magicLinkToken = locationHash.split("#token=")[1];
            if (!magicLinkToken) {
                return;
            }
            const refreshTokenOrError = await getNewRefreshToken(magicLinkToken);
            if (typeof refreshTokenOrError === "string") {
                setAppAuthenticated(refreshTokenOrError);
            } else {
                // Having error notification set here will cause double useEffect call in AppInit
                setHasErrorOccurred(true);
                bugsnag.notify(refreshTokenOrError);
            }
        },
        [bugsnag, setAppAuthenticated],
    );

    return {makeLogout, redirectToDriverPortalForLogin, loginWithLocationHash, setAppAuthenticated};
};
