import {localStorageService} from "common/services/LocalStorageService";
import {browserInfo} from "common/util/BrowserInfo";
import config from "config/index";

import {VehicleMarketplace, VehicleMarketplaceService} from "@bolteu/bolt-server-api-vehicle-marketplace";
import {HttpClientBoltServer, HttpRequestOpts} from "@bolteu/http-client-bolt-server";

import {AppJwtAuthStrategy} from "../strategies/AppJwtAuthStrategy";

export class AppApiClient extends VehicleMarketplace.ApiClient<HttpRequestOpts> {
    public httpClient: HttpClientBoltServer<VehicleMarketplace.HttpClientConstraints>;
    public authenticationStrategy: {jwt: AppJwtAuthStrategy} | undefined;
    constructor() {
        const httpClient = new HttpClientBoltServer<VehicleMarketplace.HttpClientConstraints>({
            baseUrl: config.baseUrl,
        });
        super({httpClient});
        this.httpClient = httpClient;
    }

    init(jwtAuthStrategy: AppJwtAuthStrategy) {
        const authStrategy = {
            jwt: jwtAuthStrategy,
        };
        this.authenticationStrategy = authStrategy;
        const standardRequestStrategy = {
            getStandardRequest: () => {
                const deviceInfo = browserInfo.getDeviceInfo();
                return {
                    language: localStorageService.getLanguageWithDefaultLocale(),
                    version: config.version,
                    brand: config.appBrand as unknown as VehicleMarketplaceService.BrandEnum,
                    deviceId: deviceInfo.device_uuid,
                    device_name: deviceInfo.device_name,
                    device_os_version: deviceInfo.device_os_version,
                };
            },
        };
        this.httpClient.init({
            authStrategy,
            standardRequestStrategy,
        });
    }
}
