import {Outlet} from "react-router-dom";

import Footer from "./Footer";
import Header from "./Header";

const VehicleListingsLayout = () => {
    return (
        <div className="flex h-[calc(100dvh)] flex-col justify-between">
            <Header />
            <div className="flex-1">
                <div className="flex h-full text-base leading-normal">
                    <div className="flex h-full w-full flex-col">
                        <Outlet />
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default VehicleListingsLayout;
