import {useI18n} from "common/hooks/useI18n";

const Footer = () => {
    const year = new Date().getFullYear();
    const {i18n} = useI18n();
    return (
        <footer className="flex h-20 items-center justify-end py-8 px-16 shadow">
            <p className="text-end">{i18n("site.copyright", {year})}</p>
        </footer>
    );
};

export default Footer;
