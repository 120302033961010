import {Spinner} from "@bolteu/kalep-react";
import {SpinnerSizes} from "@bolteu/kalep-react/build/components/Spinner";

interface Props {
    size?: SpinnerSizes;
}

export const FullScreenLoader = ({size}: Props) => {
    return (
        <div className="flex h-screen items-center justify-center">
            <Spinner size={size} />
        </div>
    );
};
