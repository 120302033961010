import {Suspense} from "react";
import {Route, Routes} from "react-router-dom";

import VehicleListingsLayout from "app/layouts/VehicleListingsLayout";
import {AppRoute} from "constants/routes";

import {Spinner} from "@bolteu/kalep-react";

import ListingsPage from "./listings/list";
import {Redirect} from "./Redirect";

export const AppRoutes = () => {
    return (
        <Suspense fallback={<Spinner size={700} />}>
            <Routes>
                <Route path={AppRoute.HOME} element={<VehicleListingsLayout />}>
                    <Route index element={<ListingsPage />} />
                </Route>
                <Route path="*" element={<Redirect />} />
            </Routes>
        </Suspense>
    );
};
