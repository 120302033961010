import config from "config/index";

import {HttpClientLibrary} from "@bolteu/bolt-server-api-vehicle-marketplace";

export class AppJwtAuthStrategy implements HttpClientLibrary.HttpClientJwtAuthStrategy {
    constructor(
        private getAuthTokenInternal: () => string | null | undefined,
        private updateAuthToken: () => Promise<string>,
        private authTokenParseDidThrow: () => void,
    ) {}

    getAuthToken() {
        return this.getAuthTokenInternal();
    }

    getExtraConfig() {
        return {
            updateAuthToken: async () => {
                return this.updateAuthToken();
            },
            authTokenParseDidThrow: this.authTokenParseDidThrow,
            accessTokenRefreshTimeoutBeforeExpiryInSeconds: config.accessTokenRefreshTimeoutBeforeExpiryInSeconds,
        };
    }
}
