import {browserInfo} from "common/util/BrowserInfo";

import {DriverAuthNoAuthService} from "@bolteu/bolt-server-api-vehicle-marketplace";

import {DriverAuthNoAuthApiClient} from "./api/api_clients/noAuthApiClient";
import {LocalStorageKeys, localStorageService} from "./LocalStorageService";

const removeStorageItems = () => {
    localStorageService.removeItems([LocalStorageKeys.REFRESH_TOKEN]);
};

export const logout = async () => {
    removeStorageItems();
};

export const getNewRefreshToken = async (magicLinkToken: string): Promise<string | Error> => {
    const deviceInfo = browserInfo.getDeviceInfo();
    try {
        const response = await DriverAuthNoAuthApiClient.authenticateWithMagicLink(
            // Standard request already has the version and brand, adding them here as well would cause invalid request
            {} as DriverAuthNoAuthService.AuthRequestQuery,
            {
                token: magicLinkToken,
                device_uid: deviceInfo.device_uuid,
                device_name: deviceInfo.device_name,
                device_os_version: deviceInfo.device_os_version,
            },
        );

        localStorageService.setItem(LocalStorageKeys.REFRESH_TOKEN, response.refresh_token);
        return response.refresh_token;
    } catch (e) {
        return e as Error;
    }
};

export const requestNewAccessToken = async (refreshToken: string): Promise<string> => {
    const token = await DriverAuthNoAuthApiClient.getAccessToken({
        refresh_token: refreshToken,
    });

    return token.access_token;
};

export const restoreRefreshToken = () => localStorageService.getItem(LocalStorageKeys.REFRESH_TOKEN);
