import {useContext, useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";

import {useAuthentication} from "common/hooks/useAuthentication";
import {ApplicationContextProvider} from "common/providers/appStateProvider";
import {restoreRefreshToken} from "common/services/authService";
import {FullScreenLoader} from "@fleet/common/components/FullScreenLoader";

import {AppRoutes} from "./AppRoutes";

export const AppInit = () => {
    const appState = useContext(ApplicationContextProvider);
    const [isAuthLoading, setIsAuthLoading] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    const {loginWithLocationHash, setAppAuthenticated} = useAuthentication();

    useEffect(() => {
        const initApp = async () => {
            if (appState.isAuthenticated || isAuthLoading) {
                return;
            }

            const refreshToken = restoreRefreshToken();
            if (!appState.isAppReady && location.hash) {
                setIsAuthLoading(true);
                const locationHash = location.hash;
                // Remove hash (magic link token) from URL
                navigate({search: location.search, hash: ""}, {replace: true});
                await loginWithLocationHash(locationHash);
                setIsAuthLoading(false);
                // Set app ready first so AppLoader is not rendered. Lets keep path from redirection.
                appState.setIsAppReady(true);
            } else if (refreshToken) {
                setAppAuthenticated(refreshToken);
            }
            appState.setIsAuthCompleted(true);
        };
        initApp();
    }, [appState, loginWithLocationHash, location, navigate, isAuthLoading, setAppAuthenticated]);

    if (!appState.isAuthCompleted) {
        return <FullScreenLoader size={700} />;
    }

    return <AppRoutes />;
};
