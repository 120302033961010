import {useI18n} from "common/hooks/useI18n";
import {useTailwindViewport} from "@fleet/common/hooks/useTailwindViewport";
import {getVehiclePictureByType} from "@fleet/common/utils/vehicle/getVehiclePictureByType";

import {VehicleMarketplaceService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {VehicleMarketplaceNoAuthService} from "@bolteu/bolt-server-api-vehicle-marketplace";
import {Chip, Spinner} from "@bolteu/kalep-react";
import {Check} from "@bolteu/kalep-react-icons";

import BodyType = VehicleMarketplaceService.CarModelBodyType;

export interface TableListing extends Omit<VehicleMarketplaceNoAuthService.Listing, "status"> {
    is_user_applied: boolean;
}

export interface ListingsTableBodyProps {
    listings: TableListing[];
    isLoading: boolean;
}

const ListingTile = (props: {listing: TableListing; className: string}) => {
    const {i18n} = useI18n();
    const {listing, className} = props;

    const offerPeriod = listing.offer_period ? i18n(`listings.offer_period.${listing.offer_period}`) : "";
    return (
        <div className={`${className} pb-4`}>
            <div className="flex justify-center">
                <img src={getVehiclePictureByType(BodyType.SEDAN)} alt="Vehicle" className="h-56 object-cover" />
            </div>
            <div className="mx-8 text-xl font-semibold">{`${listing.offer_price}${listing.offer_price_currency}/${offerPeriod}`}</div>
            <div className="mx-8 flex h-8 justify-between">
                <div className="text-secondary flex items-end text-sm">
                    {`${listing.vehicle_manufacturer} ${listing.vehicle_model_name}, ${listing.vehicle_years}`}
                </div>
                {listing.is_user_applied && (
                    <Chip startIcon={<Check />} label={i18n("listings.is_applied")} size="sm" appearance="action" />
                )}
            </div>
        </div>
    );
};

const ListingsTableBody = (props: ListingsTableBodyProps) => {
    const viewport = useTailwindViewport();
    const isMobileView = viewport === "sm";

    const {isLoading, listings} = props;
    if (isLoading) {
        return (
            <div className="flex items-center justify-center">
                <Spinner size={500} />
            </div>
        );
    }

    if (isMobileView) {
        return (
            <div className="flex flex-col">
                {listings.map((x) => (
                    <ListingTile className="border-separator border-b border-solid last:border-b-0" listing={x} />
                ))}
            </div>
        );
    }

    return (
        <div className="grid border-collapse grid-cols-4">
            {listings.map((x, i) => (
                <ListingTile
                    key={x.id}
                    className={`border-separator border-r border-t border-solid ${(i + 1) % 4 === 0 && "border-r-0"} ${
                        i < 4 && "border-t-0"
                    }`}
                    listing={x}
                />
            ))}
        </div>
    );
};

export default ListingsTableBody;
