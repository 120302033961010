import {useI18n} from "common/hooks/useI18n";

import {Typography} from "@bolteu/kalep-react";

import ListingsTable from "./components/ListingsTable";

const ListingsPage = () => {
    const {i18n} = useI18n();

    return (
        <>
            <div className="flex w-full flex-col justify-end gap-7 bg-neutral-50 py-10 px-16 shadow">
                <Typography variant="heading-m-accent" fontSize="text-6xl">
                    {i18n("banner.title")} <br />
                    {i18n("banner.subtitle")}
                </Typography>
                <Typography>{i18n("banner.description")}</Typography>
            </div>
            <ListingsTable />
        </>
    );
};

export default ListingsPage;
