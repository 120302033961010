import {localStorageService} from "common/services/LocalStorageService";
import {CommonBrowserInfo} from "@fleet/common/services/CommonBrowserInfo";

class BrowserInfo extends CommonBrowserInfo {
    constructor() {
        super(localStorageService.getItem, localStorageService.setItem);
    }
}

export const browserInfo = new BrowserInfo();
