import {useCallback, useContext, useEffect, useMemo, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";

import {FetchStatus, useFetch, useFetchNoAuth} from "common/hooks/useFetch";
import {useI18n} from "common/hooks/useI18n";
import {ApplicationContextProvider} from "common/providers/appStateProvider";
import {VehicleMarketplaceNoAuthApiClient} from "common/services/api/api_clients/noAuthApiClient";
import {Api} from "common/services/api/apiProvider";
import config from "config/index";
import TableSearchField from "@fleet/common/components/table/TableSearchField";
import {useTailwindViewport} from "@fleet/common/hooks/useTailwindViewport";

import {VehicleMarketplaceNoAuthService, VehicleMarketplaceService} from "@bolteu/bolt-server-api-vehicle-marketplace";
import {Select, SelectOption} from "@bolteu/kalep-react";

import ListingsTableBody, {TableListing} from "./ListingsTableBody";

const fetchAuthenticatedFunction = (api: Api, body: VehicleMarketplaceService.SearchPartnerListingsRequest) =>
    api.vehicleMarketplace.partnerDriverWebVehicleListingSearch(body);
const fetchNonAuthenticatedFunction = (body: VehicleMarketplaceNoAuthService.SearchListingsRequest) =>
    VehicleMarketplaceNoAuthApiClient.webVehicleListingSearch(body);

const ListingsTable = () => {
    const {i18n} = useI18n();
    const navigate = useNavigate();
    const appState = useContext(ApplicationContextProvider);
    const viewport = useTailwindViewport();
    const isMobileView = viewport === "sm";

    const params = useParams();
    const [searchTerm, setSearchTerm] = useState<string>("");
    const [selectedCity, setSelectedCity] = useState<SelectOption | null>(null);
    const {cities} = appState;

    const availableCities = useMemo(() => {
        return (cities || []).map((city) => ({value: city.id, title: city.local_name ?? city.name}));
    }, [cities]);

    const {
        fetch: authenticatedFetch,
        data: authListings,
        status: authLoadStatus,
    } = useFetch(fetchAuthenticatedFunction);
    const {
        fetch: nonAuthenticatedFetch,
        data: noAuthListings,
        status: noAuthLoadStatus,
    } = useFetchNoAuth(fetchNonAuthenticatedFunction);

    useEffect(() => {
        if (cities !== null) {
            const currentCity = cities.find((c) => c.name === params.city);
            if (currentCity) {
                setSelectedCity({value: currentCity.id, title: currentCity.local_name ?? currentCity.name});
                if (!appState.isAppReady) {
                    appState.setIsAppReady(true);
                }
            } else {
                window.location.href = config.driverPortalUrl;
            }
        }
    }, [params.city, cities, appState.isAppReady, appState]);

    useEffect(() => {
        if (appState.isAuthenticated && authenticatedFetch && selectedCity?.value) {
            authenticatedFetch({city_id: Number(selectedCity.value), query: searchTerm});
        } else if (!appState.isAuthenticated && nonAuthenticatedFetch && selectedCity?.value) {
            nonAuthenticatedFetch({city_id: Number(selectedCity.value), query: searchTerm});
        }
    }, [
        authenticatedFetch,
        nonAuthenticatedFetch,
        appState.isAuthenticated,
        selectedCity?.value,
        searchTerm,
        appState.isAppReady,
    ]);

    const onCityChange = useCallback(
        (selected: SelectOption | SelectOption[] | null) => {
            if (selected) {
                const option = selected as SelectOption;
                const city = (cities || []).find((c) => c.id === option.value);
                setSelectedCity(option);
                if (city) {
                    navigate(`/${city.name}`);
                }
            }
        },
        [navigate, cities],
    );

    const onSearchChange = useCallback((search: string) => {
        setSearchTerm(search);
    }, []);

    const listings: TableListing[] = useMemo(() => {
        if (appState.isAuthenticated) {
            return (authListings?.rows || []).map((listing) => ({
                ...listing,
                is_user_applied: listing.is_user_applied ?? false,
            }));
        }

        return (noAuthListings?.rows || []).map((listing) => ({
            ...listing,
            is_user_applied: false,
        }));
    }, [appState.isAuthenticated, authListings, noAuthListings]);

    return (
        <div className="px-16 pb-8">
            {!isMobileView && (
                <div className="grid grid-cols-12 gap-4 py-8">
                    <div className="col-span-5">
                        <TableSearchField onChange={onSearchChange} placeholder={i18n("listings.table.search")} />
                    </div>
                    <div className="col-span-5" />
                    <div className="col-span-2">
                        <Select onChange={onCityChange} options={availableCities} value={selectedCity} fullWidth />
                    </div>
                </div>
            )}
            {isMobileView && (
                <Select
                    rootClassName="py-8"
                    onChange={onCityChange}
                    options={availableCities}
                    value={selectedCity}
                    fullWidth
                />
            )}
            <ListingsTableBody
                listings={listings}
                isLoading={[noAuthLoadStatus, authLoadStatus].includes(FetchStatus.Loading)}
            />
        </div>
    );
};

export default ListingsTable;
